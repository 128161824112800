<i18n>
{
  "en": {
    "page-title": "Category",
    "general-type": "Main Categories",
    "actress-type": "Actress Types",
    "play-type": "Play Content",
    "costume-type": "Costume",
    "site-specific-type": "Other"
  },
  "ja": {
    "page-title": "カテゴリー",
    "general-type": "メインカテゴリー",
    "actress-type": "女優タイプ",
    "play-type": "プレイ内容",
    "costume-type": "コスチューム",
    "site-specific-type": "その他"
  }
}
</i18n>

<template>
<div class="contents">
  <div class="heading">
    <h1>{{ $t('page-title') }}</h1>
  </div>
  <!-- general type -->
  <div class="section-wide divider">
    <div class="heading">
      <h2>{{ $t('general-type') }}</h2>
    </div>
    <ul class="flex-list list-category" v-if="categoriesMap">
      <li class="list-item"
        v-for="category in generalCategories"
        :key="category.category_id"
        @click="clickTrack('Category Page', categoriesMap[category.category_id].name.ja);"
      >
        <router-link :to="`/search/?c=${category.category_id}`">
          {{ categoriesMap[category.category_id].name[locale] }}
        </router-link>
        <span class="list-count">({{ categoriesMap[category.category_id].movie_count }})</span>
      </li>
    </ul>
  </div>
  <!-- actress type -->
  <div class="section-wide divider">
    <div class="heading">
      <h2>{{ $t('actress-type') }}</h2>
    </div>
    <ul class="flex-list list-category" v-if="categoriesMap">
      <li class="list-item"
        v-for="category in actressCategories"
        :key="category.category_id"
        @click="clickTrack('Category Page', categoriesMap[category.category_id].name.ja);"
      >
        <router-link :to="`/search/?c=${category.category_id}`">
          {{ categoriesMap[category.category_id].name[locale] }}
        </router-link>
        <span class="list-count">({{ categoriesMap[category.category_id].movie_count }})</span>
      </li>
    </ul>
  </div>
  <!-- play type -->
  <div class="section-wide divider">
    <div class="heading">
      <h2>{{ $t('play-type') }}</h2>
    </div>
    <ul class="flex-list list-category" v-if="categoriesMap">
      <li class="list-item"
        v-for="category in playCategories"
        :key="category.category_id"
        @click="clickTrack('Category Page', categoriesMap[category.category_id].name.ja);"
      >
        <router-link :to="`/search/?c=${category.category_id}`">
          {{ categoriesMap[category.category_id].name[locale] }}
        </router-link>
        <span class="list-count">({{ categoriesMap[category.category_id].movie_count }})</span>
      </li>
    </ul>
  </div>
  <!-- costume type -->
  <div class="section-wide divider">
    <div class="heading">
      <h2>{{ $t('costume-type') }}</h2>
    </div>
    <ul class="flex-list list-category" v-if="categoriesMap">
      <li class="list-item"
        v-for="category in costumeCategories"
        :key="category.category_id"
        @click="clickTrack('Category Page', categoriesMap[category.category_id].name.ja);"
      >
        <router-link :to="`/search/?c=${category.category_id}`">
          {{ categoriesMap[category.category_id].name[locale] }}
        </router-link>
        <span class="list-count">({{ categoriesMap[category.category_id].movie_count }})</span>
      </li>
    </ul>
  </div>
  <!-- site-specific type -->
  <div class="section-wide divider">
    <div class="heading">
      <h2>{{ $t('site-specific-type') }}</h2>
    </div>
    <ul class="flex-list list-category" v-if="categoriesMap">
      <li class="list-item"
        v-for="category in siteSpecificCategories"
        :key="category.category_id"
        @click="clickTrack('Category Page', categoriesMap[category.category_id].name.ja);"
      >
        <router-link :to="`/search/?c=${category.category_id}`">
          {{ categoriesMap[category.category_id].name[locale] }}
        </router-link>
        <span class="list-count">({{ categoriesMap[category.category_id].movie_count }})</span>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint arrow-body-style: ["error", "always"] */

import orderBy from 'lodash/orderBy';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const VR_CATEGORY_ID = 94;

export default {
  data() {
    return {
      siteCategories: {},
      categoriesMap: null,
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();
    this.siteCategories = await bfAPI.getSiteCategories();
    this.categoriesMap = await bfAPI.getCategories();
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    generalCategories() {
      return this.orderedCategoryList('general').filter((i) => {
        return Object.prototype.hasOwnProperty.call(this.categoriesMap[i.category_id], 'movie_count')
          && this.categoriesMap[i.category_id].movie_count > 0;
      });
    },
    actressCategories() {
      return this.orderedCategoryList('actress').filter((i) => {
        return Object.prototype.hasOwnProperty.call(this.categoriesMap[i.category_id], 'movie_count')
          && this.categoriesMap[i.category_id].movie_count > 0;
      });
    },
    playCategories() {
      return this.orderedCategoryList('play').filter((i) => {
        return Object.prototype.hasOwnProperty.call(this.categoriesMap[i.category_id], 'movie_count')
          && this.categoriesMap[i.category_id].movie_count > 0;
      });
    },
    costumeCategories() {
      return this.orderedCategoryList('costume').filter((i) => {
        return Object.prototype.hasOwnProperty.call(this.categoriesMap[i.category_id], 'movie_count')
          && this.categoriesMap[i.category_id].movie_count > 0;
      });
    },
    siteSpecificCategories() {
      // return this.orderedCategoryList('specific').filter(i => this.categoriesMap[i.category_id].movie_count && (!this.newXPEligible || (this.newXPEligible && i.category_id !== VR_CATEGORY_ID)));
      return this.orderedCategoryList('specific').filter((i) => {
        // only show category if it has a movie count AND they are a classic user
        // OR
        // if the category has a movie count AND they are a newXP user AND the category isn't VR
        //   (OR the category is VR and the newXP "hide VR" config is disabled)
        return Object.prototype.hasOwnProperty.call(this.categoriesMap[i.category_id], 'movie_count')
          && this.categoriesMap[i.category_id].movie_count > 0
          && (!this.newXPEligible
            || (this.newXPEligible && (i.category_id !== VR_CATEGORY_ID || (i.category_id === VR_CATEGORY_ID && process.env.VUE_APP_NEW_XP_HIDE_VR === 'false'))));
      });
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
  },
  methods: {
    orderedCategoryList(categoryType) {
      // note: this is set as a "method" as opposed to a "computed" function because we are
      // passing a parameter to the function... "Difference between computed property and method
      // is that computed properties are cached and change only when their dependencies change -
      // that's why you can't pass parameters there. Methods are evaluated every time you call
      // them." (https://stackoverflow.com/questions/40522634/can-i-pass-parameters-in-computed-properties-in-vue-js)
      return orderBy(this.siteCategories[categoryType], 'category_priority');
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
